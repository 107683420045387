import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import {
  studentBlogsLink,
  studentGuidesLink,
  useCountryContext,
} from '../../../../contexts/countryContext'

import Link from '../../../atoms/Link'

import './styles.scss'

const PrimaryNavigationInternational = ({ className }) => {
  const navClasses = classNames('primary-nav', className)
  const { linkBase } = useCountryContext()

  return (
    <nav className={navClasses}>
      <ul className="primary-nav-international__list">
        <li className="primary-nav-international__item">
          <Link
            className="primary-nav-international__link"
            to={studentGuidesLink()}
          >
            Guides
          </Link>
        </li>
        <li className="primary-nav-international__item">
          <Link
            className="primary-nav-international__link"
            to={studentBlogsLink()}
          >
            Blog
          </Link>
        </li>
        <li className="primary-nav-international__item">
          <Link
            className="primary-nav-international__link"
            to={`${linkBase}/advertise`}
          >
            Advertise
          </Link>
        </li>
      </ul>
    </nav>
  )
}

PrimaryNavigationInternational.propTypes = {
  className: PropTypes.string,
}

export default PrimaryNavigationInternational
