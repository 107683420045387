import React from 'react';
import NextLink, { LinkProps } from 'next/link';
import AfsButton from '@afs/components/Button';
import Link from '../Link';
export interface ButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  className?: string;
  to?: string;
  href?: string;
  scroll?: boolean;
  children?: React.ReactNode;
  onClick?: React.MouseEventHandler;
  selected?: boolean;
  primary?: boolean;
  secondary?: boolean;
  inactive?: boolean;
  dark?: boolean;
  violet?: boolean;
  instantBook?: boolean;
  wide?: boolean;
  size?: 'normal' | 'medium' | 'small';
  type?: 'button' | 'submit';
  form?: string;
}
interface RenderLinkComponentProps extends LinkProps {
  to?: string;
  children: React.ReactNode;
}
const Button = (props: ButtonProps) => {
  const renderLinkComponent = ({
    to,
    href,
    scroll,
    children,
    ...props
  }: RenderLinkComponentProps) => {
    if (to) {
      return <NextLink href={to} scroll={scroll} {...props}>
          {children}
        </NextLink>;
    }
    return <Link href={href} {...props} data-sentry-element="Link" data-sentry-component="renderLinkComponent" data-sentry-source-file="index.tsx">
        {children}
      </Link>;
  };
  return <AfsButton {...props} renderLinkComponent={renderLinkComponent} data-sentry-element="AfsButton" data-sentry-component="Button" data-sentry-source-file="index.tsx" />;
};
export default Button;