import React from 'react';
interface ThorMobilePrideProps extends React.HTMLAttributes<SVGElement> {
  className?: string;
  isWinking: boolean;
}
const ThorMobilePride = ({
  className,
  isWinking,
  ...props
}: ThorMobilePrideProps) => <svg className={className} viewBox="0 0 98 98" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} data-sentry-element="svg" data-sentry-component="ThorMobilePride" data-sentry-source-file="ThorMobilePride.tsx">
    <mask id="mask0_8978_1839" style={{
    maskType: 'alpha'
  }} maskUnits="userSpaceOnUse" x="0" y="0" width="98" height="99" data-sentry-element="mask" data-sentry-source-file="ThorMobilePride.tsx">
      <path fillRule="evenodd" clipRule="evenodd" d="M69.8554 78.62C71.72 80.2287 71.9987 83.1361 70.4769 85.1078C63.8826 93.6677 56.6975 98.0037 49.1082 98.0037C48.8601 98.0037 48.6148 97.9977 48.3696 97.9889C35.9335 97.5284 27.6836 85.3764 27.338 84.8569C25.9529 82.776 26.4211 79.8952 28.3832 78.4252C30.3509 76.9582 33.06 77.4482 34.4507 79.5203C34.5734 79.7004 40.6576 88.4993 48.7152 88.7649C53.6567 88.9715 58.7432 85.7365 63.7238 79.2783C65.2455 77.3036 67.9964 77.0025 69.8554 78.62Z" fill="#EC6B1E" data-sentry-element="path" data-sentry-source-file="ThorMobilePride.tsx" />

      <rect x="25.5342" y="48.5322" width="16.3294" height="16.3614" rx="8.16471" fill="#EC6B1E" data-sentry-element="rect" data-sentry-source-file="ThorMobilePride.tsx" />

      <rect width={isWinking ? '20.935128' : '16.3294'} height={isWinking ? '8.9090117' : '16.3614'} x={isWinking ? '53.677577' : '56.1365'} y={isWinking ? '52.336027' : '48.5322'} rx={isWinking ? '4.6770082' : '8.16472'} fill="#EC6B1E" data-sentry-element="rect" data-sentry-source-file="ThorMobilePride.tsx" />

      <path d="M96.1557 50.9813C98.7007 48.3267 98.599 44.1213 95.9298 41.593L53.9023 1.75576C52.5324 0.455099 50.7292 3.58112e-05 48.996 3.58112e-05C47.2292 3.58112e-05 45.4652 0.457909 44.0953 1.75576L25.2551 19.6166V6.58758C25.2551 2.95248 22.2892 1.22599e-05 18.6285 1.22599e-05C14.9707 1.22599e-05 12.0048 2.95248 12.0048 6.58758V32.1766L2.07064 41.593C-0.598621 44.1241 -0.700304 48.3267 1.84185 50.9813C3.15529 52.3494 4.91503 53.0405 6.67476 53.0405C8.32999 53.0405 9.98803 52.4309 11.2817 51.2061L48.9988 15.4534L86.7187 51.2061C88.0124 52.4309 89.6676 53.0405 91.3228 53.0405C93.0854 53.0405 94.8451 52.3494 96.1557 50.9813Z" fill="black" data-sentry-element="path" data-sentry-source-file="ThorMobilePride.tsx" />
    </mask>
    <g mask="url(#mask0_8978_1839)" data-sentry-element="g" data-sentry-source-file="ThorMobilePride.tsx">
      <rect width="117" height="192" transform="matrix(-1 2.11122e-09 2.11181e-09 1 105 -47.0005)" fill="url(#paint0_linear_8978_1839)" data-sentry-element="rect" data-sentry-source-file="ThorMobilePride.tsx" />
    </g>
    <defs data-sentry-element="defs" data-sentry-source-file="ThorMobilePride.tsx">
      <linearGradient id="paint0_linear_8978_1839" x1="2.48182" y1="129.5" x2="96.0036" y2="126.978" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="ThorMobilePride.tsx">
        <stop stopColor="#936EFF" data-sentry-element="stop" data-sentry-source-file="ThorMobilePride.tsx" />
        <stop offset="0.205" stopColor="#8E9EFE" data-sentry-element="stop" data-sentry-source-file="ThorMobilePride.tsx" />
        <stop offset="0.4" stopColor="#7FB666" data-sentry-element="stop" data-sentry-source-file="ThorMobilePride.tsx" />
        <stop offset="0.59" stopColor="#FFCA05" data-sentry-element="stop" data-sentry-source-file="ThorMobilePride.tsx" />
        <stop offset="0.79" stopColor="#FF7C3C" data-sentry-element="stop" data-sentry-source-file="ThorMobilePride.tsx" />
        <stop offset="0.995" stopColor="#FF524D" data-sentry-element="stop" data-sentry-source-file="ThorMobilePride.tsx" />
      </linearGradient>
    </defs>
  </svg>;
export default ThorMobilePride;