import React, { useState } from 'react';
import { LinkProps } from 'next/link';
import classNames from 'classnames';
import ThorDesktop from './ThorDesktop';
import ThorMobile from './ThorMobile';
import ThorDesktopPride from './ThorDesktopPride';
import ThorMobilePride from './ThorMobilePride';
import ThorDesktopHalloween from './ThorDesktopHalloween';
import ThorMobileHalloween from './ThorMobileHalloween';
import styles from './styles.module.scss';
export interface LinkComponentProps extends LinkProps {
  className: string;
  onMouseEnter: React.MouseEventHandler<HTMLAnchorElement>;
  onMouseLeave: React.MouseEventHandler<HTMLAnchorElement>;
  onFocus: React.FocusEventHandler<HTMLAnchorElement>;
  onBlur: React.FocusEventHandler<HTMLAnchorElement>;
  children: React.ReactNode;
}
export interface LogoProps extends LinkProps {
  className?: string;
  renderLinkComponent: (props: LinkComponentProps) => JSX.Element;
}
const getLogos = ({
  isWinking,
  isJune,
  isHalloween
}: {
  isWinking: boolean;
  isJune: boolean;
  isHalloween: boolean;
}) => {
  const defaultLogos = <>
      <ThorDesktop key="desktop" className={classNames(styles.icon, styles.desktopIcon)} isWinking={isWinking} data-testid="thor-desktop" />
      <ThorMobile key="mobile" className={classNames(styles.icon, styles.mobileIcon)} isWinking={isWinking} data-testid="thor-mobile" />
    </>;
  const prideLogos = <>
      <ThorDesktopPride key="desktop-pride" className={classNames(styles.icon, styles.desktopIcon)} isWinking={isWinking} data-testid="thor-desktop-pride" />
      <ThorMobilePride key="mobile-pride" className={classNames(styles.icon, styles.mobileIcon)} isWinking={isWinking} data-testid="thor-mobile-pride" />
    </>;
  const halloweenLogos = <>
      <ThorDesktopHalloween key="desktop-halloween" className={classNames(styles.icon, styles.desktopIcon)} isWinking={isWinking} data-testid="thor-desktop-halloween" />
      <ThorMobileHalloween key="mobile-halloween" className={classNames(styles.icon, styles.mobileIcon)} isWinking={isWinking} data-testid="thor-mobile-halloween" />
    </>;
  if (isJune) return prideLogos;
  if (isHalloween) return halloweenLogos;
  return defaultLogos;
};
const Logo = ({
  className,
  renderLinkComponent,
  ...props
}: LogoProps) => {
  const [isWinking, setIsWinking] = useState(false);
  const handleEnter = () => setIsWinking(true);
  const handleLeave = () => setIsWinking(false);
  const month = new Date().getMonth();
  const date = new Date().getDate();
  const isJune = month === 5;
  const isHalloween = month === 9 && date >= 17 && date <= 31;
  return renderLinkComponent({
    className: classNames(styles.logo, {
      [styles.logoStandard]: !isJune && !isHalloween,
      [styles.logoHalloween]: isHalloween
    }, className),
    onMouseEnter: handleEnter,
    onMouseLeave: handleLeave,
    onFocus: handleEnter,
    onBlur: handleLeave,
    children: <>
        {getLogos({
        isWinking,
        isJune,
        isHalloween
      })}
        <span className={styles.text}>Accommodation for Students</span>
      </>,
    ...props
  });
};
export default Logo;