import React from 'react';
interface ThorMobileHalloweenProps extends React.HTMLAttributes<SVGElement> {
  className?: string;
  isWinking: boolean;
}
const ThorMobileHalloween = ({
  className,
  isWinking,
  ...props
}: ThorMobileHalloweenProps) => <svg className={className} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 113 99" {...props} data-sentry-element="svg" data-sentry-component="ThorMobileHalloween" data-sentry-source-file="ThorMobileHalloween.tsx">
    <g clipPath="url(#a-mobile)" data-sentry-element="g" data-sentry-source-file="ThorMobileHalloween.tsx">
      <circle cx="61.776" cy="49.052" r="49.052" fill="#F88639" data-sentry-element="circle" data-sentry-source-file="ThorMobileHalloween.tsx" />
      <mask id="b-mobile" width="99" height="99" x="12" y="0" maskUnits="userSpaceOnUse" style={{
      maskType: 'alpha'
    }} data-sentry-element="mask" data-sentry-source-file="ThorMobileHalloween.tsx">
        <circle cx="61.776" cy="49.052" r="49.052" fill="#FFD47C" data-sentry-element="circle" data-sentry-source-file="ThorMobileHalloween.tsx" />
      </mask>
      <g fill="#EC6B1E" mask="url(#b-mobile)" data-sentry-element="g" data-sentry-source-file="ThorMobileHalloween.tsx">
        <circle cx="79.673" cy="92.138" r="13.92" data-sentry-element="circle" data-sentry-source-file="ThorMobileHalloween.tsx" />
        <circle cx="56.473" cy="83.521" r="5.303" data-sentry-element="circle" data-sentry-source-file="ThorMobileHalloween.tsx" />
        <circle cx="31.947" cy="12.594" r="12.594" data-sentry-element="circle" data-sentry-source-file="ThorMobileHalloween.tsx" />
        <circle cx="26.644" cy="32.48" r="4.64" data-sentry-element="circle" data-sentry-source-file="ThorMobileHalloween.tsx" />
        <circle cx="100.885" cy="43.086" r="4.64" data-sentry-element="circle" data-sentry-source-file="ThorMobileHalloween.tsx" />
        <circle cx="96.908" cy="20.549" r="7.291" data-sentry-element="circle" data-sentry-source-file="ThorMobileHalloween.tsx" />
      </g>
      <path fill="#1C304A" d="M.258 49.515s9.295.244 14.337 6.087c0 0-1.078 8.134 3.032 9.235l.983-3.667s.466 2.37 2.52 2.921c2.056.55 3.645-1.27 3.645-1.27l-.983 3.668c4.11 1.101 7.243-6.482 7.243-6.482 7.289-2.539 15.461 1.897 15.461 1.897-4.672.994-7.15 8.19-7.15 8.19-8.22-2.202-10.045 4.609-10.045 4.609-9.714-4.85-11.82 3.009-11.82 3.009s2.106-7.858-8.73-8.516c0 0 1.824-6.81-6.396-9.013 0 0 1.451-7.472-2.097-10.668Z" data-sentry-element="path" data-sentry-source-file="ThorMobileHalloween.tsx" />
      <g fill="#fff" clipPath="url(#c-mobile)" data-sentry-element="g" data-sentry-source-file="ThorMobileHalloween.tsx">
        <path fillRule="evenodd" d="M93.34 45.444a4.307 4.307 0 0 1 .15 6.176 4.486 4.486 0 0 1-3.225 1.354 4.49 4.49 0 0 1-3.073-1.206L62.02 28.25 36.848 51.768a4.495 4.495 0 0 1-3.075 1.206 4.493 4.493 0 0 1-3.225-1.354 4.309 4.309 0 0 1 .153-6.175l6.63-6.194V22.419c0-2.392 1.979-4.334 4.42-4.334 2.443 0 4.422 1.942 4.422 4.334v8.57L58.746 19.24a4.49 4.49 0 0 1 3.271-1.193 4.482 4.482 0 0 1 3.274 1.193L93.34 45.444ZM75.937 68.44c1.244 1.058 1.43 2.97.415 4.267-4.401 5.63-9.196 8.483-14.261 8.483-.166 0-.33-.004-.493-.01-8.3-.303-13.805-8.296-14.036-8.638-.924-1.368-.612-3.263.698-4.23 1.313-.965 3.12-.643 4.049.72.082.118 4.142 5.906 9.52 6.081 3.297.136 6.692-1.992 10.016-6.24 1.016-1.3 2.851-1.497 4.092-.433Z" clipRule="evenodd" data-sentry-element="path" data-sentry-source-file="ThorMobileHalloween.tsx" />
        <rect width="10.898" height="10.762" x="47.045" y="48.912" rx="5.381" data-sentry-element="rect" data-sentry-source-file="ThorMobileHalloween.tsx" />

        <rect width={isWinking ? '13.532' : '10.898'} height={isWinking ? '6.089' : '10.762'} x={isWinking ? '65.287' : '67.469'} y={isWinking ? '50.78' : '48.912'} rx={isWinking ? '3.082' : '5.381'} data-sentry-element="rect" data-sentry-source-file="ThorMobileHalloween.tsx" />
      </g>
      <path fill="#1C304A" d="M80.276 27.192s5.335-5.06 11.421-4.613c0 0 3.945 5.15 6.86 3.468l-1.502-2.6s1.586 1.065 3.043.224 1.328-2.747 1.328-2.747l1.501 2.6c2.914-1.683.426-7.675.426-7.675 2.655-5.495 9.705-7.584 9.705-7.584-2.056 3.168.582 8.578.582 8.578-5.828 3.365-3.04 8.193-3.04 8.193-8.143 2.72-4.926 8.292-4.926 8.292s-3.217-5.572-9.644.12c0 0-2.788-4.828-8.616-1.464 0 0-3.366-4.989-7.138-4.792Z" data-sentry-element="path" data-sentry-source-file="ThorMobileHalloween.tsx" />
    </g>
    <defs data-sentry-element="defs" data-sentry-source-file="ThorMobileHalloween.tsx">
      <clipPath id="a-mobile" data-sentry-element="clipPath" data-sentry-source-file="ThorMobileHalloween.tsx">
        <path fill="#fff" d="M0 0h112.763v98.103H0z" data-sentry-element="path" data-sentry-source-file="ThorMobileHalloween.tsx" />
      </clipPath>
      <clipPath id="c-mobile" data-sentry-element="clipPath" data-sentry-source-file="ThorMobileHalloween.tsx">
        <path fill="#fff" d="M29.319 18.042H94.72v63.147H29.319z" data-sentry-element="path" data-sentry-source-file="ThorMobileHalloween.tsx" />
      </clipPath>
    </defs>
  </svg>;
export default ThorMobileHalloween;