import React from 'react';
interface ThorMobileProps extends React.HTMLAttributes<SVGElement> {
  className?: string;
  isWinking: boolean;
}
const ThorMobile = ({
  className,
  isWinking,
  ...props
}: ThorMobileProps) => <svg className={className} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 36 36" {...props} data-sentry-element="svg" data-sentry-component="ThorMobile" data-sentry-source-file="ThorMobile.tsx">
    <path fill="#EC6B1E" fillRule="evenodd" d="M25.661 28.88c.685.592.787 1.66.228 2.384-2.422 3.144-5.061 4.737-7.85 4.737-.09 0-.18-.002-.27-.005-4.569-.17-7.6-4.633-7.726-4.824-.51-.765-.337-1.823.384-2.363.722-.539 1.718-.359 2.228.403.045.066 2.28 3.298 5.24 3.396 1.816.075 3.684-1.113 5.514-3.485.559-.726 1.57-.836 2.252-.242Z" clipRule="evenodd" data-sentry-element="path" data-sentry-source-file="ThorMobile.tsx" />

    <rect width="5.999" height="6.01" x="9.38" y="17.828" fill="#EC6B1E" rx="2.999" data-sentry-element="rect" data-sentry-source-file="ThorMobile.tsx" />

    <rect width={isWinking ? '7.691' : '5.999'} height={isWinking ? '3.2725' : '6.01'} x={isWinking ? '19.7187' : '20.622'} y={isWinking ? '19.2253' : '17.828'} fill="#EC6B1E" rx={isWinking ? '1.7179' : '2.999'} data-sentry-element="rect" data-sentry-source-file="ThorMobile.tsx" />

    <path fill="#EC6B1E" d="M35.322 18.728a2.43 2.43 0 0 0-.083-3.449L19.801.645C19.298.167 18.635 0 17.999 0c-.65 0-1.297.168-1.8.645L9.276 7.206V2.42C9.277 1.085 8.187 0 6.843 0A2.427 2.427 0 0 0 4.41 2.42v9.4L.76 15.28a2.43 2.43 0 0 0-.083 3.448 2.455 2.455 0 0 0 1.775.756c.608 0 1.217-.224 1.692-.674L18 5.677 31.856 18.81c.475.45 1.083.674 1.691.674.648 0 1.294-.254 1.776-.756Z" data-sentry-element="path" data-sentry-source-file="ThorMobile.tsx" />
  </svg>;
export default ThorMobile;